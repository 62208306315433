import SVNav from '@apps/www/src/www/components/SVNav';
import SVResourceCardTemplate from '@apps/www/src/www/components/SVResourceCardTemplate';
import SVPageMeta from '@apps/www/src/www/containers/SVPageMeta';
import SVRedirect from '@apps/www/src/www/containers/SVRedirect';
import useAuthSettings from '@apps/www/src/www/hooks/useAuthSettings';
import useAuthTeam from '@apps/www/src/www/hooks/useAuthTeam';
import useAuthUser from '@apps/www/src/www/hooks/useAuthUser';
import useHasCourse from '@apps/www/src/www/hooks/useHasCourse';
import useIsLoggedIn from '@apps/www/src/www/hooks/useIsLoggedIn';
import useLanguageSetting from '@apps/www/src/www/hooks/useLanguageSetting';
import useScrollAnimation from '@apps/www/src/www/hooks/useScrollAnimation';
import useViewportName from '@apps/www/src/www/hooks/useViewportName';
import { toggleEditing } from '@apps/www/src/www/reducers/grid';
import { openGridSettings } from '@apps/www/src/www/reducers/ui';
import SVActions from '@pkgs/shared-client/components/SVActions';
import SVButton, { SVButtonUSES } from '@pkgs/shared-client/components/SVButton';
import SVDropdown from '@pkgs/shared-client/components/SVDropdown';
import SVDropdownContent from '@pkgs/shared-client/components/SVDropdownContent';
import SVFlexSpacer from '@pkgs/shared-client/components/SVFlexSpacer';
import SVImage from '@pkgs/shared-client/components/SVImage';
import SVLandingPageFooter from '@pkgs/shared-client/components/SVLandingPageFooter';
import SVLink from '@pkgs/shared-client/components/SVLink';
import SVMinimalVideoPlayer from '@pkgs/shared-client/components/SVMinimalVideoPlayer';
import SVPageHeader from '@pkgs/shared-client/components/SVPageHeader';
import SVPageMargins from '@pkgs/shared-client/components/SVPageMargins';
import SVPageSubtitle from '@pkgs/shared-client/components/SVPageSubtitle';
import SVPageTitle from '@pkgs/shared-client/components/SVPageTitle';
import config from '@pkgs/shared-client/config';
import SVWithBodyClass from '@pkgs/shared-client/containers/SVWithBodyClass';
import { scrollToElement } from '@pkgs/shared-client/helpers/dom';
import { formatURL } from '@pkgs/shared-client/helpers/format';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import SVCourseCoverPNG from '@pkgs/shared-client/img/course/course-cover-trailer.png';
import SVFigmaCourseTemplatePNG from '@pkgs/shared-client/img/course/course-figma-template.png';
import SVCourseFooterImages from '@pkgs/shared-client/img/course/course-footer-images.png';
import SVParallaxImage02 from '@pkgs/shared-client/img/course/course-parallax-02.png';
import SVParallaxImage from '@pkgs/shared-client/img/course/course-parallax.png';
import SVProjectsImage02 from '@pkgs/shared-client/img/course/course-projects-left-image.png';
import SVProjectsImage from '@pkgs/shared-client/img/course/course-projects.png';
import SVFigmaCourseContentPNG from '@pkgs/shared-client/img/course/figma-course-content.png';
import iconCheckItems from '@pkgs/shared-client/img/course/icon-check-items.svg';
import ogImage from '@pkgs/shared-client/img/course/og-image.jpg';
import SVAndreSouzaAvatar from '@pkgs/shared-client/img/course/social_proof/andre_souza_avatar.png';
import SVAndreaAlabastroAvatar from '@pkgs/shared-client/img/course/social_proof/andrea_alabastro_avatar.png';
import SVAshleyGauntSeoAvatar from '@pkgs/shared-client/img/course/social_proof/ashley_gaunt_seo_avatar.png';
import SVColinDunnAvatar from '@pkgs/shared-client/img/course/social_proof/colin_dunn_avatar.png';
import SVDiegoMendesAvatar from '@pkgs/shared-client/img/course/social_proof/diego_mendes_avatar.png';
import SVFabioSassoAvatar from '@pkgs/shared-client/img/course/social_proof/fabio_sasso_avatar.png';
import SVGuilhermeDienstmannAvatar from '@pkgs/shared-client/img/course/social_proof/guilherme_dienstmann_avatar.png';
import SVJoshKillAvatar from '@pkgs/shared-client/img/course/social_proof/josh_kill_avatar.png';
import SVMarkLaughlinAvatar from '@pkgs/shared-client/img/course/social_proof/mark_laughlin_avatar.png';
import SVMichaelLashfordAvatar from '@pkgs/shared-client/img/course/social_proof/michael_lashford_avatar.png';
import SVMilyMcClellandAvatar from '@pkgs/shared-client/img/course/social_proof/mily_mcclelland_avatar.png';
import SVCourseAfterTemplateMP4 from '@pkgs/shared-client/videos/course-after-template.mp4';
import SVCourseTrailerEnglish from '@pkgs/shared-client/videos/course-trailer-english.mp4';
import SVCourseTrailer from '@pkgs/shared-client/videos/course-trailer.mp4';
import SVMeetYourHostVideo from '@pkgs/shared-client/videos/meet-your-host.mp4';
import Language, { getLanguageLabel } from '@pkgs/shared/enums/Language';
import ViewportKind from '@pkgs/shared/enums/ViewportKind';
import clsx from 'clsx';
import { ChevronDown } from 'lucide-react';
import { useRouter } from 'next/router';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const SAVEE_URL_COURSE_CHECKOUT = '/api/course/checkout/';

const _ScrollToPricingButton = ({ label, className }: { label: string; className?: string }) => {
	const handleClick = useEventCallback(() => {
		scrollToElement(document.getElementById('pricing') as HTMLElement);
	});

	return (
		<SVButton
			use={SVButtonUSES.PRIMARY}
			className={clsx('w-fit rounded-full px-8 py-6 font-medium', className)}
			onClick={handleClick}
		>
			{label}
		</SVButton>
	);
};

const _GetCourseButton = ({
	label,
	className,
	language,
	usePadding = true,
}: {
	label: string;
	className?: string;
	language: (typeof Language)[keyof typeof Language];
	usePadding?: boolean;
}) => {
	const HOTMART_URL = config.course.hotmartURL;

	return (
		<SVButton
			Component={SVLink}
			use={SVButtonUSES.PRIMARY}
			to={language === Language.PT_BR ? HOTMART_URL : SAVEE_URL_COURSE_CHECKOUT}
			className={clsx('w-fit rounded-full font-medium', usePadding && 'px-8 py-6', className)}
		>
			{label}
		</SVButton>
	);
};

const _Navbar = () => {
	const [language, setLanguage] = useLanguageSetting();
	const dispatch = useDispatch();
	const authSettings = useAuthSettings(['email', 'unreadNotificationsCount']);
	const handleToggleEditing = useEventCallback(() => dispatch(toggleEditing()));
	const handleOpenGridSettings = useEventCallback(() => dispatch(openGridSettings()));
	const team = useAuthTeam();
	const user = useAuthUser(['name', 'avatarURL', 'url']);
	const router = useRouter();
	const isLoggedIn = useIsLoggedIn();

	return (
		<div className="h-nav-height relative flex items-center">
			<SVPageMargins className="flex flex-grow items-center">
				<SVNav.NavLogo isEditing={false} locationPath={router.asPath} />
				<SVFlexSpacer />
				<div className="flex items-center space-x-3">
					<SVDropdown
						triggerType="click"
						renderTrigger={({ isOpen: _, ...props }) => (
							<SVActions.Item
								onClick={() => {}}
								{...props}
								className="flex items-center justify-center space-x-2"
							>
								<div className="text-base font-semibold text-gray-600">
									{getLanguageLabel(language)}
								</div>
								<ChevronDown className="h-5 w-5 text-gray-600" />
							</SVActions.Item>
						)}
						renderContent={() => (
							<SVDropdownContent.Links>
								{Object.values(Language).map((language, index) => (
									<SVDropdownContent.Links.Item
										key={index}
										title={getLanguageLabel(language)}
										onClick={() => setLanguage(language)}
									>
										{getLanguageLabel(language)}
									</SVDropdownContent.Links.Item>
								))}
							</SVDropdownContent.Links>
						)}
					/>
					{isLoggedIn ? (
						<SVNav.Avatar
							userEmail={authSettings?.email || ''}
							site={null}
							userName={user?.name || ''}
							userAvatarURL={user?.avatarURL || ''}
							userURL={user?.url || ''}
							team={team}
							unreadNotificationsCount={authSettings?.unreadNotificationsCount || 0}
							isDarkMode={true}
							showGridControls={false}
							onToggleEditing={handleToggleEditing}
							userHasExpiredSubscription={false}
							onOpenGridSettings={handleOpenGridSettings}
							onOpenGridUpload={() => {}}
							userCanSeeBillingPage={true}
						/>
					) : null}
				</div>
			</SVPageMargins>
		</div>
	);
};

const _SocialProofList = ({
	SOCIAL_PROOF_LIST,
	isSmallNav,
}: {
	SOCIAL_PROOF_LIST: {
		title: string;
		userDescription: string;
		userName: string;
		avatarURL: string;
	}[];
	isSmallNav: boolean | string;
}) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const { t } = useTranslation('course');
	const visibleItems = isSmallNav && !isOpen ? SOCIAL_PROOF_LIST.slice(0, 5) : SOCIAL_PROOF_LIST;

	return (
		<SVPageMargins>
			<div className="flex-center relative mx-auto w-full max-w-screen-xl">
				<div className="-sm:columns-1 -md:columns-2 -lg:columns-3 relative columns-4 gap-8 space-y-8">
					{visibleItems.map((item, index) => (
						<div
							key={index}
							className="break-inside-avoid rounded-xl border border-gray-800 p-4 text-center"
						>
							<div className="text-xl text-gray-500">{item.title}</div>
							<div className="flex-center mt-4">
								<SVImage
									src={item.avatarURL}
									className="h-5 w-5 rounded-full"
									alt={item.userName + ' avatar'}
								/>
							</div>
							<div className="mt-3 text-lg text-gray-300">{item.userName}</div>
							<div className="text-sm text-gray-500">{item.userDescription}</div>
						</div>
					))}
					{isSmallNav && !isOpen ? (
						<div className="absolute bottom-0 left-0 right-0 mt-5 flex h-[300px] items-end justify-center bg-gradient-to-t from-black to-transparent">
							<SVButton
								use={SVButtonUSES.PRIMARY}
								onClick={() => setIsOpen(true)}
								className="mb-5 w-fit rounded-full px-8 py-6 font-medium"
							>
								{t('quotesSection.buttonCopy')}
							</SVButton>
						</div>
					) : null}
				</div>
			</div>
		</SVPageMargins>
	);
};

const _EpisodesParallax = () => {
	const containerRef = useRef<HTMLDivElement>(null);
	const row1Ref = useRef<HTMLDivElement>(null);
	const row2Ref = useRef<HTMLDivElement>(null);

	useScrollAnimation({
		containerRef,
		update: (progress: number, { windowHeight }) => {
			const movement = Math.min(150, (windowHeight / 900) * 150);

			if (row1Ref.current && row2Ref.current) {
				// 0 to 1 to -1 to 1
				const progressBoth = progress * 2 - 1;

				row1Ref.current.style.transform = `translateX(${progressBoth * -1 * movement}px)`;
				row2Ref.current.style.transform = `translateX(${progressBoth * movement}px)`;
			}
		},
		enableAnimationAlways: true,
	});

	return (
		<div className="w-full overflow-hidden pt-12" ref={containerRef}>
			<div className="-sm:mb-[2.7%] relative mb-[1.8%]" ref={row1Ref}>
				<div
					className="-sm:pb-[19.89%] relative ml-[-25%] w-[150%] bg-contain bg-center bg-repeat-x pb-[13.26%]"
					style={{ backgroundImage: `url(${SVParallaxImage})` }}
				/>
			</div>
			<div className="relative mb-[1.8%]" ref={row2Ref}>
				<div
					className="-sm:pb-[19.89%] relative ml-[-25%] w-[150%] bg-contain bg-center bg-repeat-x pb-[13.26%]"
					style={{ backgroundImage: `url(${SVParallaxImage02})` }}
				/>
			</div>
		</div>
	);
};

const _Projects = () => {
	const containerRef = useRef<HTMLDivElement | null>(null);
	const image1Ref = useRef<HTMLDivElement | null>(null);
	const image2Ref = useRef<HTMLDivElement | null>(null);

	useScrollAnimation({
		containerRef,
		bottomThreshold: 0.8,
		update: (progress, { windowHeight }) => {
			const movement = Math.min(100, (windowHeight / 900) * 100);

			if (image1Ref.current && image2Ref.current) {
				image1Ref.current.style.transform = `translateY(${progress * movement}px)`;
				image2Ref.current.style.transform = `translateY(${progress * movement * -1}px)`;
			}
		},
		enableAnimationAlways: true,
	});

	return (
		<div className="-md:mt-16 flex w-full" ref={containerRef}>
			<div className="flex-center relative h-full w-full space-x-6">
				<div ref={image1Ref}>
					<SVImage
						src={SVProjectsImage02}
						className="-sm:max-w-[45vw] -lg:max-h-[600px] -lg:w-[250px] -mt-4 max-h-[800px] w-[350px]"
						alt="Projects"
					/>
				</div>
				<div ref={image2Ref}>
					<SVImage
						src={SVProjectsImage}
						className="-sm:max-w-[45vw] -lg:max-h-[600px] -lg:w-[250px] -mt-10 max-h-[800px] w-[350px]"
						alt="Projects"
					/>
				</div>
			</div>
		</div>
	);
};

const _ExtraContentList = () => {
	const { t } = useTranslation('course');

	const EXTRA_CONTENT_LIST: {
		title: string;
		description: string;
		source: string;
	}[] = [
		{
			title: t('extraBonusSection.extraBonus01.title'),
			description: t('extraBonusSection.extraBonus01.description'),
			source: SVCourseAfterTemplateMP4,
		},
		{
			title: t('extraBonusSection.extraBonus02.title'),
			description: t('extraBonusSection.extraBonus02.description'),
			source: SVFigmaCourseTemplatePNG,
		},
		{
			title: t('extraBonusSection.extraBonus03.title'),
			description: t('extraBonusSection.extraBonus03.description'),
			source: SVFigmaCourseContentPNG,
		},
	];

	return <SVResourceCardTemplate items={EXTRA_CONTENT_LIST} />;
};

const _MeetYourHostVideo = () => {
	const containerRef = useRef<HTMLDivElement | null>(null);
	const videoRef = useRef<HTMLDivElement | null>(null);

	useScrollAnimation({
		containerRef,
		update: (progress, { windowHeight }) => {
			if (videoRef.current) {
				const movement = Math.min(100, (windowHeight / 900) * 100);

				videoRef.current.style.transform = `translateY(${progress * movement}px)`;
			}
		},
		enableAnimationAlways: true,
	});

	return (
		<div
			className="-md:mt-10 -sm:pb-[70%] -sm:h-[400px] -lg:h-[680px] relative inline-block h-[800px] w-full pb-[60%]"
			ref={containerRef}
		>
			<div ref={videoRef} className="absolute inset-0 overflow-hidden rounded-xl">
				<video
					src={SVMeetYourHostVideo}
					className="absolute inset-0 h-full w-full object-cover"
					controls={false}
					autoPlay
					muted
					playsInline
					loop
				/>
			</div>
		</div>
	);
};

const _Page = () => {
	const { t } = useTranslation('course');
	const hasCourse = useHasCourse();
	const [language] = useLanguageSetting();

	const OLD_PRICE =
		language === Language.EN_US
			? '<span class="line-through">$499</span>'
			: 'De <span class="line-through">R$1.999 </span> por apenas';
	const NEW_PRICE = language === Language.EN_US ? '$249' : 'R$1.199';

	const OFFER_DETAILS_LIST: string[] = [
		t('courseDetailsSection.included.01'),
		t('courseDetailsSection.included.02'),
		t('courseDetailsSection.included.03'),
		t('courseDetailsSection.included.04'),
		t('courseDetailsSection.included.05'),
		t('courseDetailsSection.included.06'),
		t('courseDetailsSection.included.07'),
	];

	const SOCIAL_PROOF_LIST: {
		title: string;
		userDescription: string;
		userName: string;
		avatarURL: string;
	}[] = [
		{
			title: t('quotesSection.quote01.title'),
			userDescription: t('quotesSection.quote01.user_description'),
			userName: 'Andre Souza',
			avatarURL: SVAndreSouzaAvatar,
		},
		{
			title: t('quotesSection.quote05.title'),
			userDescription: t('quotesSection.quote05.user_description'),
			userName: 'Mily McClelland',
			avatarURL: SVMilyMcClellandAvatar,
		},
		{
			title: t('quotesSection.quote09.title'),
			userDescription: t('quotesSection.quote09.user_description'),
			userName: 'Michael Lashford',
			avatarURL: SVMichaelLashfordAvatar,
		},
		{
			title: t('quotesSection.quote02.title'),
			userDescription: t('quotesSection.quote02.user_description'),
			userName: 'Ashley Gaunt-Seo',
			avatarURL: SVAshleyGauntSeoAvatar,
		},
		{
			title: t('quotesSection.quote06.title'),
			userDescription: t('quotesSection.quote06.user_description'),
			userName: 'Mark Laughlin',
			avatarURL: SVMarkLaughlinAvatar,
		},
		{
			title: t('quotesSection.quote10.title'),
			userDescription: t('quotesSection.quote10.user_description'),
			userName: 'Guilherme Dienstmann',
			avatarURL: SVGuilhermeDienstmannAvatar,
		},
		{
			title: t('quotesSection.quote03.title'),
			userDescription: t('quotesSection.quote03.user_description'),
			userName: 'Colin Dunn',
			avatarURL: SVColinDunnAvatar,
		},
		{
			title: t('quotesSection.quote07.title'),
			userDescription: t('quotesSection.quote07.user_description'),
			userName: 'Fabio Sasso',
			avatarURL: SVFabioSassoAvatar,
		},
		{
			title: t('quotesSection.quote11.title'),
			userDescription: t('quotesSection.quote11.user_description'),
			userName: 'Josh Kill',
			avatarURL: SVJoshKillAvatar,
		},
		{
			title: t('quotesSection.quote04.title'),
			userDescription: t('quotesSection.quote04.user_description'),
			userName: 'Andrea Alabastro',
			avatarURL: SVAndreaAlabastroAvatar,
		},
		{
			title: t('quotesSection.quote08.title'),
			userDescription: t('quotesSection.quote08.user_description'),
			userName: 'Diego Mendes',
			avatarURL: SVDiegoMendesAvatar,
		},
	];

	const viewportName = useViewportName();

	const isSmallNav =
		viewportName === 'small' ||
		(!viewportName && ViewportKind.MOBILE) ||
		viewportName === 'medium' ||
		viewportName === 'medium-portrait';

	if (hasCourse) {
		return <SVRedirect to="/course/watch/" />;
	}

	const meta = {
		title: t('title'),
		description: t('description'),
		image: {
			url: formatURL(config.staticURL, ogImage),
			width: 1200,
			height: 630,
		},
	};

	return (
		<>
			<SVPageMeta {...meta} />
			<_Navbar />
			<div className="flex w-full max-w-full flex-col overflow-hidden">
				<SVPageHeader className="-sm:px-6 -sm:my-12 my-24">
					<SVPageTitle className="type-huge mb-6 font-medium text-gray-300">
						{t('title')}
					</SVPageTitle>
					<SVPageSubtitle>{t('description')}</SVPageSubtitle>
				</SVPageHeader>

				<div className="-sm:mb-24 relative mx-auto mb-40 h-full w-full max-w-screen-2xl overflow-hidden rounded-xl px-[var(--page-margin)] md:aspect-video">
					<SVMinimalVideoPlayer
						src={language === Language.EN_US ? SVCourseTrailerEnglish : SVCourseTrailer}
						className="h-full w-full rounded-xl object-cover"
						poster={SVCourseCoverPNG}
						autoPlay
						muted
						loop
					/>
				</div>
				<div className="flex flex-col items-center">
					<SVPageTitle className="-md:px-6 type-title mb-5 font-medium text-gray-300">
						{t('episodesSection.title')}
					</SVPageTitle>
					<div className="type-subnav -md:px-6 text-pretty text-center text-3xl text-gray-500">
						{t('episodesSection.description')}
					</div>
					<_EpisodesParallax />
				</div>
				<div className="-md:flex-col-reverse -md:space-y-10 -md:px-6 -md:space-x-0 -lg:space-x-10 -sm:my-24 mx-auto my-40 flex w-full max-w-screen-xl items-center justify-center space-x-32">
					<_MeetYourHostVideo />
					<div className="flex-center w-full">
						<div className="-md:text-center -md:max-w-lg flex max-w-[320px] flex-col text-start">
							<div className="type-subnav text-gray-700">
								{t('meetYourHostSection.label')}
							</div>
							<SVPageTitle className="-md:text-center mt-5 text-start font-medium text-gray-300">
								{t('meetYourHostSection.title')}
							</SVPageTitle>
							<div className="type-subnav text-pretty text-3xl text-gray-500">
								{t('meetYourHostSection.description')}
								<div className="mt-3">
									{t('meetYourHostSection.descriptionExtraLine')}
								</div>
							</div>
							<div className="-md:flex-center mt-7 flex w-full justify-start">
								<_ScrollToPricingButton
									label={t('meetYourHostSection.buttonCopy')}
								/>
							</div>
						</div>
					</div>
				</div>
				<SVPageMargins>
					<div className="-md:flex-col -md:space-y-10 -md:px-6 -md:space-x-0 -lg:space-x-10 mx-auto mb-28 mt-6 flex w-full max-w-screen-xl items-center space-x-32">
						<div className="flex-center -md:mb-20 w-full">
							<div className="-md:text-center -md:max-w-lg flex max-w-[320px] flex-col text-start">
								<div className="type-subnav text-gray-700">
									{t('practicalApplicationSection.label')}
								</div>
								<SVPageTitle className="-md:text-center mt-5 text-start font-medium text-gray-300">
									{t('practicalApplicationSection.title')}
								</SVPageTitle>
								<div className="type-subnav text-pretty text-3xl text-gray-500">
									{t('practicalApplicationSection.description')}
								</div>
								<div className="-md:flex-center mt-7 flex w-full justify-start">
									<_ScrollToPricingButton
										label={t('practicalApplicationSection.buttonCopy')}
									/>
								</div>
							</div>
						</div>
						<_Projects />
					</div>
				</SVPageMargins>
				<SVPageMargins>
					<div className="-md:mt-12 mx-auto mt-36 flex w-full max-w-screen-xl flex-col">
						<SVPageTitle className="font-medium text-gray-300">
							{t('extraBonusSection.title')}
						</SVPageTitle>
						<div className="-md:mb-0 mb-28 w-full text-pretty text-center text-3xl text-gray-500">
							{t('extraBonusSection.description')}
						</div>
						<_ExtraContentList />
					</div>
				</SVPageMargins>
				<div className="flex-center -md:mt-24 mt-40 w-full flex-col">
					<div className="flex-center w-full flex-col px-[var(--page-margin)]">
						<SVPageTitle className="mb-5 w-full text-center font-medium text-gray-300">
							{t('quotesSection.title')}
						</SVPageTitle>
						<div className="-md:mb-16 mb-24 w-full text-pretty text-center text-3xl text-gray-500">
							{t('quotesSection.description')}
						</div>
					</div>
					<_SocialProofList
						SOCIAL_PROOF_LIST={SOCIAL_PROOF_LIST}
						isSmallNav={isSmallNav}
					/>
				</div>
				<SVPageMargins id="pricing">
					<div className="-sm:flex-col -sm:space-y-16 -md:mt-28 mx-auto mt-64 flex w-full max-w-screen-xl flex-row items-center justify-between">
						<div className="flex-center w-full flex-col">
							<div className="text-xl text-gray-600">
								{t('courseDetailsSection.label')}
							</div>
							<div
								className="-sm:text-3xl mt-7 text-4xl font-medium text-gray-200 opacity-30"
								dangerouslySetInnerHTML={{
									__html: OLD_PRICE,
								}}
							/>
							<div
								className="-md:text-9xl -sm:text-[23vw] -md:mt-4 text-[120px] font-semibold text-gray-200 "
								style={{ letterSpacing: '-0.06em' }}
							>
								{NEW_PRICE}
							</div>
							{language === Language.PT_BR && (
								<div className="-md:mt-3 -sm:text-3xl text-4xl text-gray-500">
									{t('courseDetailsSection.newPrice')}
								</div>
							)}
							<div
								className={clsx(
									'-md:flex-col -md:space-y-4 -md:space-x-0 flex space-x-2.5',
									language === Language.PT_BR ? 'mt-11' : 'mt-5',
								)}
							>
								<_GetCourseButton
									label={t('courseDetailsSection.buttonCopy')}
									usePadding={language === Language.EN_US}
									language={language}
								/>
								{language === Language.PT_BR && (
									<SVButton
										Component={SVLink}
										to={SAVEE_URL_COURSE_CHECKOUT}
										className="rounded-full font-medium"
									>
										Comprar pelo SAVEE
									</SVButton>
								)}
							</div>
						</div>
						<div className="flex-center w-full">
							<div className="flex w-fit flex-col items-start space-y-6">
								{OFFER_DETAILS_LIST.map((item, index) => (
									<div
										key={index}
										className="flex items-center space-x-3 text-start"
									>
										<SVImage
											src={iconCheckItems}
											className="h-4 w-4"
											alt="check items"
										/>
										<div className="type-subnav text-gray-500">{item}</div>
									</div>
								))}
							</div>
						</div>
					</div>
					<div className="type-subnav mt-28 w-full space-x-1 text-center font-semibold text-gray-500">
						{t('footerSection.title')}
						<a
							href="https://savee.it/contact-us/"
							className="ml-1 underline"
							target="_blank"
							rel="noreferrer"
						>
							{t('footerSection.linkCopy')}
						</a>
						{t('footerSection.textAfterLink')}
					</div>
				</SVPageMargins>
				<div className="-sm:mt-24 -md:mt-32 relative mt-52 flex min-h-[180px] w-full justify-center pb-[20%]">
					<SVImage
						src={SVCourseFooterImages}
						className="absolute h-full w-auto max-w-none"
						alt="Course footer images"
					/>
				</div>

				<SVPageMargins>
					<div className="-sm:hidden">
						<SVLandingPageFooter className="mb-10 mt-10" />
					</div>
				</SVPageMargins>
			</div>
		</>
	);
};

const Page = SVWithBodyClass('theme-dark')(_Page);

// @ts-ignore
Page.withNavHidden = ({ hasCourse }) => !hasCourse;

export default Page;
