import useScrollAnimation from '@apps/www/src/www/hooks/useScrollAnimation';
import SVImage from '@pkgs/shared-client/components/SVImage';
import React, { useRef } from 'react';

type Item = {
	title: string;
	description?: string;
	action?: JSX.Element;
	source: string;
} & ({ description: string; action?: never } | { action: JSX.Element; description?: never });

type SVResourceCardTemplateProps = {
	items: Item[];
};

const SVResourceCardTemplate = ({ items }: SVResourceCardTemplateProps) => {
	const itemRefs = useRef<React.RefObject<HTMLDivElement>[]>(
		Array.from({ length: items.length }, () => React.createRef<HTMLDivElement>()),
	);
	const containerRef = useRef<HTMLDivElement>(null);

	const applyAnimation = (
		ref: React.RefObject<HTMLDivElement>,
		progress: number,
		offset: number,
		movement: number,
	) => {
		if (ref.current) {
			const p = Math.max(0, Math.min(1, (progress - offset) / 0.6));
			ref.current.style.opacity = `${p}`;
			ref.current.style.transform = `translateY(${(1 - p) * movement}px)`;
		}
	};

	useScrollAnimation({
		containerRef,
		topThreshold: 0.4,
		clamp: true,
		update: (progress, { windowHeight }) => {
			const movement = Math.min(150, (windowHeight / 900) * 150);
			itemRefs.current.forEach((ref, index) =>
				applyAnimation(ref, progress, index * 0.2, movement),
			);
		},
	});

	return (
		<div
			className="-md:flex-col -md:space-x-0 -md:space-y-24 relative flex w-full flex-row space-x-20"
			ref={containerRef}
		>
			{items.map((item, index) => (
				<div key={index} className="relative flex-1" ref={itemRefs.current[index]}>
					<div className="flex-center group relative w-full">
						{item.source.endsWith('.mp4') ? (
							<video
								src={item.source}
								autoPlay
								muted
								playsInline
								controls={false}
								loop
								className="h-full w-full rounded-xl"
							/>
						) : (
							<SVImage
								src={item.source}
								className="h-full w-full rounded-lg"
								alt={item.title + ' preview'}
							/>
						)}
					</div>
					<div className="-md:text-center -md:text-3xl mt-5 mb-2.5 text-xl font-medium">
						{item.title}
					</div>
					<div className="-md:text-center -md:text-xl text-base text-gray-500">
						{item.description}
					</div>
					{item.action}
				</div>
			))}
		</div>
	);
};

export default SVResourceCardTemplate;
